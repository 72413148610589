import * as React from "react"
import Box from "@mui/material/Box"
import Slider from "@mui/material/Slider"

const marks = [
  {
    value: 3,
    label: "3 months",
  },
  {
    value: 6,
    label: "6 months",
  },
  {
    value: 12,
    label: "12 months",
  },
  {
    value: 18,
    label: "18 months",
  },
  // {
  //   value: 36,
  //   label: "36 months",
  // },
  // {
  //   value: 48,
  //   label: "48 months",
  // },
  // {
  //   value: 60,
  //   label: "60 months",
  // },
]

function valueLabelFormat(value) {
  return marks.findIndex((mark) => mark.value === value) + 1
}

export default function DiscreteSliderValues({ setNewAdvance, loan_term }) {
  const handleSliderChange = (event, newValue) => {
    setNewAdvance((oldValue) => {
      return { ...oldValue, loan_term: newValue }
    })
  }

  return (
    <Box sx={{ width: "90%", margin: "0 auto" }}>
      <Slider
        aria-label="Restricted values"
        value={loan_term}
        defaultValue={12}
        valueLabelFormat={valueLabelFormat}
        onChange={handleSliderChange}
        step={null}
        marks={marks}
        min={3}
        max={18}
      />
    </Box>
  )
}
