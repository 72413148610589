// For better UX, the advance creation process is broken down into 4 steps
// These 4 steps are 4 different pages linked in a logical chain

// Stage 2 requires the user to provide some documents for this property
// Only 1 document type is required (the lease agreement)
// The other 2 types of documents are optional

// NB: dealing with file upload is tricky and I did my best to make it work here

import React from "react"
import { useNavigate, useParams, Link } from "react-router-dom"
import { useState, useEffect } from "react"
import axios from "axios"
import {
  Box,
  Button,
  Typography,
  Alert,
  Checkbox,
  Tooltip,
  TextField,
} from "@mui/material"
import AdvanceStepper from "../../components/AdvanceStepper"
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight"
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft"
import GenericContainer from "../../ui/GenericContainer"
import DragDrop from "./DragDrop"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"

import { DemoContainer } from "@mui/x-date-pickers/internals/demo"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { DatePicker } from "@mui/x-date-pickers/DatePicker"
import dayjs from "dayjs"

axios.defaults.baseURL = process.env.REACT_APP_ENV_LINK

const NewAdvance1 = () => {
  // We fetch the advance ID from the URL using the below hook
  let { advanceID } = useParams()

  const [isLoading, setIsLoading] = useState(true)

  const navigate = useNavigate()

  const [newAdvance, setNewAdvance] = useState({})
  const [buttonLoading, setButtonLoading] = useState(false)
  const [rentAgreementFiles, setRentAgreementFiles] = useState([])
  const [rentProtectionFiles, setRentProtectionFiles] = useState([])
  const [newRentAgreementFiles, setNewRentAgreementFiles] = useState([])
  const [newRentProtectionFiles, setNewRentProtectionFiles] = useState([])
  const [selectedButton, setSelectedButton] = useState("yes")

  // Initial useEffect to check for the local auth token
  // and fetching of a single advance
  useEffect(() => {
    const token = window.localStorage.getItem("factored_token")
    document.title = `Factored - Advance ${advanceID}`
    if (!token) {
      navigate("/")
    } else if (token) {
      axios.defaults.headers.common["Authorization"] = `Token ${token}`
      getSingleAdvance()
      getAllDocuments()
    }
  }, [])

  // This fetches the advance using the ID from the useParams hook
  const getSingleAdvance = async () => {
    setIsLoading(true)
    try {
      const response = await axios.get(`api/advances/${advanceID}/`)
      // console.log(data)
      setNewAdvance(response.data)
      setIsLoading(false)
    } catch (error) {
      console.log(error)
    }
  }

  const {
    witness_full_name,
    witness_email_address,
    combined_estimated_ltv_for_properties,
  } = newAdvance

  const [witnessDetails, setWitnessDetails] = useState({
    witnessFullName: witness_full_name,
    witnessEmailAddress: witness_email_address,
    estimatedCombinedLtv: combined_estimated_ltv_for_properties,
  })

  const getAllDocuments = async () => {
    setIsLoading(true)
    const { data } = await axios.get(
      `api/advance-documents/manage/${advanceID}/`,
    )

    setRentAgreementFiles(
      data.filter((file) => file.type_of_document === "rent_agreement"),
    )
    setRentProtectionFiles(
      data.filter((file) => file.type_of_document === "rent_protection"),
    )

    try {
    } catch (error) {
      console.log(error)
    }
  }

  const [errorText, setErrorText] = useState("")

  // This sends a PATCH request to the backend with new advance files
  // And then navigates the user to the next stage
  const handleSubmit = (e) => {
    e.preventDefault()
    const formData = new FormData()

    if (checkedCheckbox === false) {
      setErrorText("Please confirm the above condition before proceeding.")
      return
    }

    newRentAgreementFiles.forEach((file) =>
      formData.append("rent_agreement", file.file),
    )

    newRentProtectionFiles.forEach((file) =>
      formData.append("rent_protection", file.file),
    )

    if (areDocumentsMissing()) {
      setButtonLoading(false)
      return
    }

    const updateExistingAdvance = async () => {
      setButtonLoading(true)

      try {
        const response = await axios.patch(`api/advances/${advanceID}/`, {
          witness_full_name: witnessDetails.witnessFullName,
          witness_email_address: witnessDetails.witnessEmailAddress,
          combined_estimated_ltv_for_properties:
            witnessDetails.estimatedCombinedLtv,
        })
      } catch (error) {
        // setErrorText(error.response.data.detail)
        console.log(error)
      }

      try {
        await axios.patch(
          `api/advance-documents/manage/${advanceID}/`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          },
        )
        // console.log("API response", response.data)

        setErrorText("")
        navigate(`/new-advance-2/${advanceID}/`)
      } catch (error) {
        console.log("context error", error)
      }
    }

    updateExistingAdvance()
  }

  const [checkedCheckbox, setCheckedCheckbox] = useState(false)

  const handleTickbox = () => {
    setCheckedCheckbox(!checkedCheckbox)
    // setErrorText("")
  }

  const deleteFileRequest = async (documentId, filename, type_of_document) => {
    const extension = filename.substring(filename.lastIndexOf(".") + 1)
    try {
      const response = await axios.delete(
        `api/advance-documents/manage/${advanceID}/`,
        {
          data: {
            document_id: documentId,
            extension,
          },
        },
      )
      console.log(response)
      if (response.status === 202) {
        if (type_of_document === "rent_agreement") {
          setRentAgreementFiles(
            rentAgreementFiles.filter((file) => file.id !== documentId),
          )
        } else if (type_of_document === "rent_protection") {
          setRentProtectionFiles(
            rentProtectionFiles.filter((file) => file.id !== documentId),
          )
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  const [rentProtectionFilesError, setRentProtectionFilesError] =
    useState(false)
  const [rentAgreementFilesError, setRentAgreementFilesError] = useState(false)

  const areDocumentsMissing = () => {
    if (
      newRentProtectionFiles.length === 0 &&
      rentProtectionFiles.length === 0
    ) {
      setRentProtectionFilesError(true)
      return true
    }
    if (rentAgreementFiles.length === 0 && newRentAgreementFiles.length === 0) {
      setRentAgreementFilesError(true)
      return true
    }
  }

  useEffect(() => {
    setRentAgreementFilesError(false)
    setRentProtectionFilesError(false)
  }, [newRentAgreementFiles, newRentProtectionFiles])

  return (
    <GenericContainer>
      <AdvanceStepper stepNumber={0}></AdvanceStepper>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          gap: "1rem",
        }}
      >
        <Typography variant="h2">Documents </Typography>
        {/* <Typography variant="b1" sx={{ textAlign: "center" }}>
          Maximum size per each file is 5MB
        </Typography> */}
      </Box>

      <Typography fontWeight={600}>
        Upload your signed tenancy agreement contract(s)
      </Typography>
      <Typography fontWeight={400}>
        Tip: Upload multiple agreements across multiple properties to increase
        the likelihood of approving a larger advance sum.
      </Typography>
      <Typography>
        If you have any difficulties uploading your files, please send them to{" "}
        <a href="mailto: support@factored.co">support@factored.co</a> via{" "}
        <a target="_blank" rel="noreferrer" href="https://wetransfer.com/">
          WeTransfer
        </a>
      </Typography>

      {rentAgreementFiles.length > 0 && (
        <Table>
          {rentAgreementFiles.map((file) => {
            const { id, file_url, filename, type_of_document } = file
            return (
              <TableRow key={id}>
                <TableCell>
                  <Typography>{filename}</Typography>
                </TableCell>
                <TableCell>
                  <Button
                    variant="outlined"
                    onClick={() =>
                      window.open(file_url, "_blank", "noreferrer")
                    }
                  >
                    View File
                  </Button>
                </TableCell>
                <TableCell>
                  <Button
                    variant="outlined"
                    onClick={() => {
                      deleteFileRequest(id, filename, type_of_document)
                    }}
                  >
                    Delete File
                  </Button>
                </TableCell>
              </TableRow>
            )
          })}
        </Table>
      )}

      <DragDrop
        type={"rentAgreement"}
        newRentAgreementFiles={newRentAgreementFiles}
        setNewRentAgreementFiles={setNewRentAgreementFiles}
      ></DragDrop>

      {/* <Typography fontWeight={600}>
        Upload the last three months of your rent received*
      </Typography> */}

      <Typography fontWeight={600}>
        Please upload a recent (within 1 month) credit report, we recommend{" "}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.checkmyfile.com/checkout.htm"
        >
          CheckMyFile
        </a>
        *
      </Typography>

      {rentProtectionFiles.length > 0 && (
        <Table>
          {rentProtectionFiles.map((file) => {
            const { id, file_url, filename, type_of_document } = file
            return (
              <TableRow key={id}>
                <TableCell>
                  <Typography>{filename}</Typography>
                </TableCell>
                <TableCell>
                  <Button
                    variant="outlined"
                    onClick={() =>
                      window.open(file_url, "_blank", "noreferrer")
                    }
                  >
                    View File
                  </Button>
                </TableCell>
                <TableCell>
                  <Button
                    variant="outlined"
                    onClick={() => {
                      deleteFileRequest(id, filename, type_of_document)
                    }}
                  >
                    Delete File
                  </Button>
                </TableCell>
              </TableRow>
            )
          })}
        </Table>
      )}

      <DragDrop
        type={"rentProtection"}
        newRentProtectionFiles={newRentProtectionFiles}
        setNewRentProtectionFiles={setNewRentProtectionFiles}
      ></DragDrop>

      <Box>
        <Checkbox
          checked={checkedCheckbox}
          onChange={handleTickbox}
          name="acceptTermsConditions"
          sx={{
            margin: "0px",
            padding: "0px",
            "& .MuiSvgIcon-root": { fontSize: 24 },
          }}
        />
        <label>
          I confirm that all tenants have successfully passed financial due
          diligence and reference checks.
        </label>
      </Box>
      {errorText && <Alert severity="error">{errorText}</Alert>}
      {buttonLoading && (
        <Alert severity="warning">
          Uploading your document(s)... This shouldn't take more than a few
          seconds
        </Alert>
      )}
      {rentAgreementFilesError && (
        <Alert severity="error">
          Please ensure that you have uploaded at least one signed tenancy
          agreement
        </Alert>
      )}
      {rentProtectionFilesError && (
        <Alert severity="error">
          Please ensure that you have uploaded at least one credit report
        </Alert>
      )}

      {/* <Typography>
        Please enter or select your date of birth in DD/MM/YYYY format
      </Typography> */}
      {/* 
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DemoContainer components={["DatePicker"]}>
          <DatePicker
            format="DD/MM/YYYY"
            value={dayjs(user_date_of_birth) ?? dayjs().subtract(18, "years")}
            onChange={(newValue) => {
              // setDate(newValue)
              // console.log(newValue.toISOString())

              setNewAdvance({
                ...newAdvance,
                user_date_of_birth: newValue.toISOString(),
              })
            }}
            label="Date of birth*"
          />
        </DemoContainer>
      </LocalizationProvider> */}
      <Typography fontWeight={600}>
        Please indicate the ownership status of the properties you’ve submitted
      </Typography>
      <Box sx={{ width: "100%", display: "flex", gap: "2rem" }}>
        <Button
          sx={{ width: "100%" }}
          variant={selectedButton === "yes" ? "contained" : "outlined"}
          onClick={() => {
            setSelectedButton("yes")
          }}
        >
          Owned under personal name
        </Button>
        <Tooltip
          title="Contracts with entities need a witness
signature. But we'll only reach out to the witness once both parties agree on an
advance."
        >
          <Button
            onClick={() => {
              setSelectedButton("no")
            }}
            sx={{ width: "100%" }}
            variant={selectedButton === "no" ? "contained" : "outlined"}
          >
            Owned by a business I own
          </Button>
        </Tooltip>
      </Box>
      {selectedButton === "no" && (
        <>
          <TextField
            id="outlined-basic"
            label="Witness full name"
            variant="outlined"
            value={witnessDetails.witnessFullName}
            onChange={(e) =>
              setWitnessDetails((prev) => ({
                ...prev,
                witnessFullName: e.target.value,
              }))
            }
            required
            type="text"
            name="witness_full_name"
          />
          <TextField
            id="outlined-basic"
            label="Witness email address"
            variant="outlined"
            value={witnessDetails.witnessEmailAddress}
            onChange={(e) =>
              setWitnessDetails((prev) => ({
                ...prev,
                witnessEmailAddress: e.target.value,
              }))
            }
            required
            type="text"
            name="witness_email_address"
          />
        </>
      )}

      <Typography fontWeight={600}>
        Please tell us the combined estimated Loan to Value (LTV) of all the
        properties you’ve submitted.
      </Typography>

      <TextField
        id="outlined-basic"
        label="Combined estimated LTV"
        variant="outlined"
        value={witnessDetails.estimatedCombinedLtv}
        onChange={(e) =>
          setWitnessDetails((prev) => ({
            ...prev,
            estimatedCombinedLtv: e.target.value,
          }))
        }
        required
        type="text"
        name="estimatedCombinedLtv"
      />

      <Box sx={{ width: "100%", display: "flex", gap: "2rem" }}>
        <Button
          component={Link}
          to={`/new-advance-info/${advanceID}/`}
          sx={{ p: 1.5, width: "50%" }}
          variant="outlined"
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <KeyboardArrowLeftIcon></KeyboardArrowLeftIcon>
            <Typography variant="h6">Previous Step</Typography>
          </Box>
        </Button>
        <Button
          component={Link}
          to={`/new-advance-2/${advanceID}/`}
          sx={{ p: 1.5, width: "50%" }}
          variant="contained"
          onClick={handleSubmit}
          disabled={buttonLoading}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography variant="h6">Next Step</Typography>
            <KeyboardArrowRightIcon></KeyboardArrowRightIcon>
          </Box>
        </Button>
      </Box>
    </GenericContainer>
  )
}

export default NewAdvance1
